import React from 'react'
// import { Link as RouterLink } from 'react-router-dom'
import { NavBar as AppNavBar, NavBarProps } from 'compositions/NavBar'
// import { Link, Text } from 'components'
import OrdoChefsLogo from '@kitchen/theme/ordo-chefs-logo.svg'
// import { useCurrentVendorLocation } from '@kitchen/hooks'
// import { useViewport } from 'hooks'

export const NavBar: React.FC<NavBarProps> = (props) => {
  return <AppNavBar {...props} logo={<OrdoChefsLogo />}></AppNavBar>
}

export default NavBar